import React, { useEffect, useState } from 'react';

function UserDashboard() {

    return (
        <div>
            <h2>Dashboard</h2>

            Hello vidyasagar.vi (not vidyasagar.vi? Log out)

            From your account dashboard you can view your recent orders, manage your shipping and billing addresses, and edit your password and account details.

            Verification status: Verified

        </div>
    );
}

export default UserDashboard;
