import React from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function UserNavbar() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const isActive = (path) => location.pathname === path;
    const searchParams = new URLSearchParams(location.search);
    const language = searchParams.get('lang') || 'en';

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('');
      };

    return (
            <div className="user-nav">
            
                <nav className="navbar navbar-expand-xl">
                  
                        <div className="navbar-nav">
                            
                            <Link to={`/my-account?lang=${language}`} className={`nav-item nav-link ${isActive('/') ? 'active' : ''}`}>Dashboard</Link>

                            <Link to={`/my-account/orders?lang=${language}`} className={`nav-item nav-link ${isActive('/') ? 'active' : ''}`}>Orders</Link>

                            <Link to={`/my-account/account?lang=${language}`} className={`nav-item nav-link ${isActive('/') ? 'active' : ''}`}>Account details</Link>

                            <Link to={`/my-account/password?lang=${language}`} className={`nav-item nav-link ${isActive('/') ? 'active' : ''}`}>Change Password</Link>

                            <Link to="#" className='nav-item nav-link' onClick={handleLogout}>Log out</Link>
                        </div>
                </nav>
            </div>
    );
}

export default UserNavbar;
