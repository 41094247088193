// src/components/admin/AdminSidebar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/AdminNavBar.css'; // Ensure this CSS file exists for styling the sidebar

const AdminNavBar = () => {
    const [openMenus, setOpenMenus] = useState({});

    const toggleMenu = (menu) => {
        setOpenMenus((prevState) => ({
            ...prevState,
            [menu]: !prevState[menu],
        }));
    };

    return (
        <div className="admin-sidebar">
            <div className='admin-logo'>
            <Link to="/" className="navbar-brand">
            <h1 className="text-primary display-6">SATIM</h1>
            </Link>
            </div>
            <ul>
            <li>
                <Link to="/admin/site-settings">Dashboard</Link>
                </li>
                <li>
                    <button onClick={() => toggleMenu('manageStore')}>
                        Manage Store
                    </button>
                    <div className={`nested-menu ${openMenus.manageStore ? 'open' : ''}`}>
                        <ul>
                            <li><Link to="/admin/store">List Product</Link></li>
                            <li><Link to="/admin/add-store">Add Product</Link></li>
                        </ul>
                    </div>
                </li>

                <li>
                    <button onClick={() => toggleMenu('manageCategories')}>
                        Manage Categories
                    </button>
                    <div className={`nested-menu ${openMenus.manageCategories ? 'open' : ''}`}>
                        <ul>
                            <li><Link to="/admin/manage-categories">Category 1</Link></li>
                            <li><Link to="/admin/manage-categories">Category 2</Link></li>
                        </ul>
                    </div>
                </li>

                <li>
                    <button onClick={() => toggleMenu('manageOrders')}>
                        Manage Orders
                    </button>
                    <div className={`nested-menu ${openMenus.manageOrders ? 'open' : ''}`}>
                        <ul>
                            <li><Link to="/admin/manage-orders">Order 1</Link></li>
                            <li><Link to="/admin/manage-orders">Order 2</Link></li>
                        </ul>
                    </div>
                </li>
                <li>
                    <button onClick={() => toggleMenu('manageUsers')}>
                        Manage Users
                    </button>
                    <div className={`nested-menu ${openMenus.manageUsers ? 'open' : ''}`}>
                        <ul>
                            <li><Link to="/admin/manage-users">User 1</Link></li>
                            <li><Link to="/admin/manage-users">User 2</Link></li>
                        </ul>
                    </div>
                </li>
            
                <li>
                    <Link to="/admin/site-settings">Site Settings</Link>
                </li>
            </ul>
        </div>
    );
};

export default AdminNavBar;
