
import React from 'react';
import UserNavbar from './UserNavbar';

const Userayout = ({ children }) => {
    return (

        <div>
        <div className="container-fluid page-header py-4">
            <h1 className="text-center text-white display-6">My Account</h1>
            <ol className="breadcrumb justify-content-center mb-0">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item"><a href="#">My Account</a></li>
            </ol>
        </div>
        <div className='container'>
        <div className="admin-layout">
            <div className="user-content">
            <UserNavbar />
                {children}
            </div>
        </div>
        </div>
        </div>
    );
};

export default Userayout;